<template>
    <div>
        <CRow>

            <CCol sm="6" lg="6">
                <vue-dropzone :options="dropzoneOptions"
                              :useCustomSlot=true
                              ref="myVueDropzone"
                              @vdropzone-success="filesAdded"
                >
                    <div class="dropzone-custom-content">
                        <h3 class="dropzone-custom-title">{{ title }}</h3>
                        <div class="subtitle">Trascina o clicca per caricarla</div>
                    </div>
                </vue-dropzone>
            </CCol>

            <CCol sm="6" lg="6" v-if="current_image !== null">
                <div class="custom-elenco-foto">
                    <CCard class="card-custom-foto">
                        <CCardHeader>
                            <h5 class="text-anteprima-necrologio">Anteprima {{ subtitle }}</h5>
                        </CCardHeader>

                        <CCardBody>
                            <CImg
                                    class="preview_manifesto"
                                    :src="current_image.src"
                                    block
                            />
                        </CCardBody>
                    </CCard>
                </div>
            </CCol>

        </CRow>
    </div>
</template>

<script>

import 'vue2-timepicker/dist/VueTimepicker.css'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import UploadClient from '@uploadcare/upload-client'
import * as imageConversion from 'image-conversion';

export default {
    components: {
        vueDropzone: vue2Dropzone,
    },
    data: function () {
        return {
            current_image: null,
            clientUploadCare: new UploadClient({publicKey: process.env.VUE_APP_UPLOADCARE}),
            baseLink: 'https://ucarecdn.com/',
            uploadCarePublicKey: process.env.VUE_APP_UPLOADCARE,
            dropzoneOptions: {
                url: 'https://api.funer24.com/post',
                thumbnailWidth: 100,
                thumbnailHeight: 100,
                resizeQuality: 0.1,
                chunking: true,
                resizeMethod: 'crop',
                resizeWidth: 500,
                resizeHeight: 500,
                forceChunking: true,
                retryChunks: true,
                retryChunksLimit: 5,
                maxFiles: 1,
            },
        };
    },
    methods: {

        fillImgList(obj) {
            this.current_image = {'uuid': obj.uuid, 'src': this.baseLink + obj.uuid + "/"};
        },

        filesAdded(file) {
            imageConversion.compress(file, 0.2)
                .then((fileCompressed) => {
                    return fileCompressed;
                })
                .then(fileCompressed => {
                    let blobToFile = new File([fileCompressed], "name");
                    Promise.resolve(this.clientUploadCare
                        .uploadFile(blobToFile)
                        .then(blobToFile => {
                            return blobToFile;
                        })
                        .then(blobToFile => {
                            this.$emit("getPhoto", this.baseLink + blobToFile.uuid + "/");
                            this.fillImgList(blobToFile);
                            this.$refs.myVueDropzone.removeFile(file);
                        })
                    );
                });
        },
    },
    watch: {
        img: {
            immediate: true,
            handler() {
                let img = '';
                if (this.img !== null) {
                    if (this.img.length > 0) {
                        if (this.img.includes('https://ucarecdn.com/')) {
                            img = this.img.replace('https://ucarecdn.com/', '').replace('/', '');
                        }
                        let imgObj = {uuid: img}
                        this.fillImgList(imgObj)
                    }
                }
            }
        },
        title: {},
        subtitle: {},
    },
    props: ['img', 'title', 'subtitle'],
    name: "UploadSinglePhotoAndPreview"
}
</script>

<style>
img.preview_manifesto.d-block {
    width: 380px;
    margin: 0 auto;
    padding: 0 !important;
}
</style>
