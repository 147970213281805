<template>
  <div>
    <CTabs variant="pills" :active-tab="this.numberTab[this.tipologia]">
      <div class="tipologie-manifesto" v-for="(v, k) in componentName">
        <CTab :title="v" v-if="tipologia === v || tipologia === undefined">
          <component :is="k"></component>
        </CTab>
      </div>
    </CTabs>
  </div>
</template>

<script>


import Lutto from "@/views/funer24/manifesti/tipologie/Lutto";
import Ottavario from "@/views/funer24/manifesti/tipologie/Ottavario";
import Trigesimo from "@/views/funer24/manifesti/tipologie/Trigesimo";
import Anniversario from "@/views/funer24/manifesti/tipologie/Anniversario";
import Ringraziamento from "@/views/funer24/manifesti/tipologie/Ringraziamento";
import Partecipazione from "@/views/funer24/manifesti/tipologie/Partecipazione";
import PartecipazioneEsterna from "@/views/funer24/manifesti/tipologie/PartecipazioneEsterna";
import ComunicazioneLutto from "@/views/funer24/manifesti/tipologie/ComunicazioneLutto";
import RingraziamentoTrigesimo from "@/views/funer24/manifesti/tipologie/RingraziamentoTrigesimo";
import RingraziamentoOttavario from "@/views/funer24/manifesti/tipologie/RingraziamentoOttavario";
import SeiMesi from "@/views/funer24/manifesti/tipologie/SeiMesi";


export default {
  components: {
    Lutto,
    Ottavario,
    Trigesimo,
    Anniversario,
    SeiMesi,
    Ringraziamento,
    Partecipazione,
    PartecipazioneEsterna,
    ComunicazioneLutto,
    RingraziamentoOttavario,
    RingraziamentoTrigesimo,
  },
  data: function () {
    return {
      componentName: {
        'Lutto': 'Lutto',
        'Ottavario': 'Ottavario',
        'RingraziamentoOttavario': 'Ringraziamento e Ottavario',
        'Trigesimo': 'Trigesimo',
        'RingraziamentoTrigesimo': 'Ringraziamento e Trigesimo',
        'Anniversario': 'Anniversario',
        'SeiMesi': 'Sei Mesi',
        'Ringraziamento': 'Ringraziamento',
        'Partecipazione': 'Partecipazione',
        'PartecipazioneEsterna': 'Partecipazione Esterna',
        'ComunicazioneLutto': 'Comunicazione Lutto',
      },
      numberTab: {
        'Lutto': 0,
        'Ottavario': 1,
        'Ringraziamento e Ottavario': 2,
        'Trigesimo': 3,
        'Ringraziamento e Trigesimo': 4,
        'Anniversario': 5,
        'Sei Mesi': 6,
        'Ringraziamento': 7,
        'Partecipazione': 8,
        'Partecipazione Esterna': 9,
        'Comunicazione Lutto': 10,
      },
      tipologia: undefined
    };
  },
  mounted() {
    // Il parametro viene inviato dal bottone modifica nella lista manifesti
    if (this.$route.params.tipologia !== undefined) {
      this.tipologia = this.$route.params.tipologia;
    }
  },
  name: "Manifesto"
}
</script>
<style>
button.cancella-necrologio {
  margin-left: 10px;
}

.modal-body {
  font-size: 16px;
}

.vue-advanced-cropper__background, .vue-advanced-cropper__foreground {
  background: #fff !important;
}

.dropzone-custom-content {
  padding: 80px;
}

h3.dropzone-custom-title {
  font-weight: 900;
  font-size: 3vw;
}

div.custom-elenco-foto.col-md-4 {
  display: inline-flex;
}

button.btn.custom-button-delete {
  width: 50%;
}

button.btn.custom-botton-save-and-end-edit-modal.btn-info.btn-lg.btn-pill {
  width: 100%;
  height: 75px;
  font-weight: 700;
}

button.btn.pressed-reps.btn-info.btn-lg.btn-pill {
  background-color: #f9b115;
}

img.custom-image-preview.align-content-center {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.text-anteprima-necrologio {
  text-align: center;
}

.upload-example-cropper {
  border: solid 1px #EEE;
  height: 600px;
  width: auto;
  z-index: 99999999;
}

.btn-info:hover {
  color: #fff;
  background-color: #FFBF01 !important;
  border-color: #FFBF01;
}

button.btn.btn-info.btn-md {
  background-color: #670196 !important;
}

.modal-gestione-foto.modal-content > footer {
  display: none;
}

button.btn.custom-botton-save-and-end-edit-modal.btn-info.btn-lg.btn-square {

  margin-top: 24px;
  margin-left: 400px;
}

.card.card-custom-foto {
  height: 366px;
}

div#dropzone {
  height: 366px;
}

.nome_cognome_bold {
  font-weight: 700;
}

button.btn.custom-botton-save-and-end-edit-modal.btn-info.btn-lg.btn-square {
  width: 100%;
  margin: 0;
  margin-top: 10px;
}

a.nav-link {
  border-color: #d4d4d4 !important;
  font-size: 16px;
  font-weight: 500;
  color: #670196;
  background-color: white;
}

a.nav-link.active {
  /* color: black !important; */
  background-color: #670196 !important;
  color: white !important;
  font-weight: 600;
}

ul.nav.nav-pills {
  padding: 20px;
  background-color: white;
}

</style>
