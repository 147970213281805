<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardBody>
          <CDataTable
              :items="loadedItems"
              :fields="fields"
              :table-filter="{ external: true, lazy: true, label:'Cerca Necrologio', placeholder:' ' }"
              :table-filter-value.sync="tableFilterValue"
              :items-per-page="5"
              :active-page="1"
              outlined
              :noItemsView='{ noResults: "Nessun risultato da filtrare disponibile", noItems: "Nessun necrologio trovato" }'
              hover
              :loading="loading"
          >

            <template #fotoNecrologioCustom="{item}">
              <td>
                <CImg
                    v-bind:src="resolvePhoto(item)"
                    shape="rounded-circle"
                    width="100"
                    height="100"
                    className="mb-6"
                />
              </td>
            </template>

            <template #creato="{item}">
              <td>
                <h6>{{ resolveDatetime(item.created_at) }}</h6>
              </td>
            </template>

            <template #aggiornato="{item}">
              <td>
                <h6>{{ resolveDatetime(item.updated_at) }}</h6>
              </td>
            </template>

            <template #comuni="{item}">
              <td>
                <h6>{{ item.comuni.comune }}</h6>
              </td>
            </template>

            <template #bottoneVisualizzazione="{item}">
              <td>
                <CButton
                    class="m-1"
                    :size="sizeMd"
                    color="primary"
                    @click="sendNecrologio(item)"
                >
                  Seleziona Necrologio
                </CButton>
              </td>
            </template>
          </CDataTable>

          <CPagination
              v-show="pages > 1"
              :pages="pages"
              :active-page.sync="activePage"
          />

        </CCardBody>
      </CCard>

    </CCol>
  </CRow>
</template>


<script>

const apiList = '/dashboard/api/v0/necrologi/list';

const fields = [
  {
    key: 'fotoNecrologioCustom',
    label: 'Foto',
    sort: false,
    filter: false
  },
  {
    key: 'nome',
    label: 'Nome',
    sort: false,
    filter: false
  },
  {
    key: 'indirizzo',
    label: 'Indirizzo',
    sort: false,
    filter: false
  },
  {
    key: 'data_nascita',
    label: 'Data Nascita',
    sort: false,
    filter: false
  },
  {
    key: 'data_morte',
    label: 'Data Morte',
    sort: false,
    filter: false
  },
  {
    key: 'comuni',
    label: 'Comuni in cui pubblicare',
    sort: false,
    filter: false
  },
  {
    key: 'creato',
    label: 'Data Inserimento',
    sort: false,
    filter: false
  },
  {
    key: 'visualizzazioni',
    label: 'Visualizzazioni',
    sort: false,
    filter: false
  },
  {
    key: 'bottoneVisualizzazione',
    label: 'Operazioni',
    sort: false,
    filter: false
  },
]

export default {
  name: 'ListaNecrologiPreview',
  data() {
    return {
      sorterValue: {column: null, asc: true},
      columnFilterValue: {},
      tableFilterValue: '',
      activePage: 1,
      loadedItems: [],
      itemsPerPage: 10,
      loading: false,
      warningModal: false,
      pages: 0,
      currentPages: 1,
      sizeMd: 'sm',
      fields: fields
    }
  },
  watch: {
    reloadParams() {
      this.onTableChange()
    },
    filterValue: {
      immediate: true,
      handler() {
        this.tableFilterValue = this.filterValue;
      }
    },
  },
  computed: {
    reloadParams() {
      return [
        this.sorterValue,
        this.columnFilterValue,
        this.tableFilterValue,
        this.activePage
      ]
    }
  },
  methods: {
    sendNecrologio(value) {
      this.$emit("getNecrologio", value);
    },
    onTableChange() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.getData();
      }, 1000)
    },
    getData() {
      if (this.tableFilterValue.length > 0) {
        axios
            .get(apiList + '?search=' + this.tableFilterValue)
            .then((response) => {

              if (response.status === 200) {
                this.loadedItems = response.data.results;
              }
            }, (error) => {
              console.log(error);
            });
      } else {
        axios
            .get(apiList + '?page=' + this.activePage)
            .then((response) => {

              if (response.status === 200) {
                this.loadedItems = response.data.results;
              }
            }, (error) => {
              console.log(error);
            });
      }
    },
    resolvePhoto(obj) {
      if (obj !== null && obj.foto !== null) {
        return process.env.VUE_APP_CDN_URL + obj.foto.foto_pagina_manifesto_mobile;
      } else {
        return obj.foto_profilo;
      }
    },

    resolveDatetime(datetimeObj) {
      return new Date(datetimeObj).toLocaleString()
    },
  },
  created() {
    this.pages = Math.ceil(localStorage.getItem('numberNecrologi') / this.itemsPerPage)
    this.getData();
  },
  props: {
    filterValue: {
      type: String,
      default: null
    },
  },

}
</script>
<style>
table.table.table-hover {
  margin-top: 24px;
  margin-bottom: 24px;
}

button.btn.m-1.btn-primary.btn-large {
  right: 10px;
  position: absolute;
  /* padding-right: 25px; */
}

button.btn-info {
  background-color: #670196;
}

button.btn.m-1.btn-primary.btn-large {
  background-color: #670196;
}

button.btn.m-1.btn-primary.btn-sm {
  background-color: #670196;
}

</style>
