import moment from "moment/moment";

export function test(val) {
    console.log(val)
    console.log(val.nome_manifesto)
    val.nome_manifesto = 'egeo'
}


export function computeDate(data, obj) {

    if (data.data_pubblicazione !== null) {

        let data_pubblicazione = moment(data.data_pubblicazione).toDate()

        if (data_pubblicazione instanceof Object) {

            obj.data_pubblicazione = data_pubblicazione;
            if (data_pubblicazione.getMinutes() < 10) {

                obj.ora_pubblicazione = {
                    'HH': data_pubblicazione.getHours().toString(),
                    'mm': "0" + data_pubblicazione.getMinutes().toString()
                }

            } else {

                obj.ora_pubblicazione = {
                    'HH': data_pubblicazione.getHours().toString(),
                    'mm': data_pubblicazione.getMinutes().toString()
                }
            }

        }
    }

    if (data.giorno_ora_messa !== null) {

        let data_rito_ricorrenza = moment(data.giorno_ora_messa).toDate()

        if (data_rito_ricorrenza instanceof Object) {
            obj.data_rito_ricorrenza = data_rito_ricorrenza;

            if (data_rito_ricorrenza.getMinutes() < 10) {

                if (data_rito_ricorrenza.getHours() < 10) {
                    obj.ora_rito_ricorrenza = {
                        'HH': "0" + data_rito_ricorrenza.getHours().toString(),
                        'mm': "0" + data_rito_ricorrenza.getMinutes().toString()
                    }
                } else {
                    obj.ora_rito_ricorrenza = {
                        'HH': data_rito_ricorrenza.getHours().toString(),
                        'mm': "0" + data_rito_ricorrenza.getMinutes().toString()
                    }
                }

            } else {

                obj.ora_rito_ricorrenza = {
                    'HH': data_rito_ricorrenza.getHours().toString(),
                    'mm': data_rito_ricorrenza.getMinutes().toString()
                }

            }
        }
    }

}

export function normalizeDateTime(date, time) {
    console.log(date)
    console.log(time)
    if (date !== null && time !== null) {
        date.setHours(time.HH, time.mm)
        date = moment(date).utcOffset(0, true).format()
        return date;
    }
}


export const tipologie = [
    'Chiesa',
    'Chiesa parrocchiale',
    'Parrocchia',
    'Casa funeraria',
    'Concattedrale',
    'Cattedrale',
    'Abbazia',
    'Cimitero',
    'Basilica',
    'Duomo',
    'Sala del regno',
    'Moschea',
    'Camera Ardente',
    'Santuario',
    'Collegiata',
    'Cappella',
    'Sala del Commiato',
];
