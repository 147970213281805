<template>

  <div>
    <br>

    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Foto Manifesto</strong>
        </CCardHeader>
        <CCard>
          <CCardBody>

            <UploadSinglePhotoAndPreview
                @getPhoto="(img) => this.foto_manifesto = img"
                :img="foto_manifesto"
                title="Carica qui la foto del Manifesto"
                subtitle="foto manifesto"
            />
            <br>
          </CCardBody>
          <br>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Necrologio Associato</strong>
        </CCardHeader>
        <CCard>
          <CCardBody>

            <ListaNecrologiPreview
                @getNecrologio="setNecrologio"
                :filterValue="nome_manifesto"
            />
            <br>
          </CCardBody>
          <br>
        </CCard>
      </CCol>
    </CRow>


    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Info Manifesto</strong>
        </CCardHeader>
        <CCard>
          <CCardBody>

            <CInput
                v-model="nome_manifesto"
                class="bold-text"
                label="Nome completo (Nome e Cognome) (*)"
                placeholder="es: Mario rossi"
            />

            <CInput
                v-model="testo_ringraziamenti"
                label="Testo ringraziamenti"
                placeholder="es: Le famiglie Rossi e Bianchi ringraziano"
            />

          </CCardBody>
          <br>
        </CCard>
      </CCol>
    </CRow>


    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Info pubblicazione</strong>
        </CCardHeader>
        <CCard>
          <CCardBody>

            <MultiComuni v-if="this.$route.params.idManifesto == null"
                         title="Comuni in cui pubblicare (Massimo 5 comuni) (*)"
                         v-bind:selected="comuni"
                         v-bind:tipologia="tipologia"
                         v-on:getMultiComuni="setMultiComuni"
            />


            <CRow v-if="this.$route.params.idManifesto == null">
              <CCol sm="6">
                <p class="text">Pubblicazione Programmata</p>

                <CSwitch
                    label-on="Si"
                    label-off="No"
                    color="primary"
                    :checked.sync="pubblicazione_immediata"
                />

              </CCol>
              <CCol sm="6">
                <p class="text">Necessita Verifica</p>
                <CSwitch
                    label-on="Si"
                    label-off="No"
                    color="primary"
                    :checked.sync="necessita_verifica"
                />
                <br>
              </CCol>
            </CRow>

            <CRow v-if="pubblicazione_immediata === true && this.$route.params.idManifesto == null">
              <CCol sm="6">
                <br>

                <p class="bold-text">Data pubblicazione (*)</p>
                <v-date-picker
                    :value="data_pubblicazione"
                    mode="date"
                    v-model="data_pubblicazione"
                />
              </CCol>
              <CCol sm="6">
                <br>
                <p class="bold-text">Ora pubblicazione (*)</p>
                <vue-timepicker
                    manual-input
                    format="HH:mm"
                    :hour-range="[[6, 22]]"
                    :value="ora_pubblicazione"
                    v-model="ora_pubblicazione"
                ></vue-timepicker>

              </CCol>
            </CRow>
            <br>

            <CRow>
              <CCol sm="6">
                <p class="text">Blocca servizio Fiori</p>
                <CSwitch
                    label-on="Si"
                    label-off="No"
                    class="mr-1"
                    color="primary"
                    :checked.sync="blocco_servizio_fiori"
                />
              </CCol>
              <CCol sm="6" v-if="this.$route.params.idManifesto == null">
                <p class="text"> Pubblicazione pagine Facebook comunali</p>
                <CSwitch
                    label-on="Si"
                    label-off="No"
                    class="mr-1"
                    color="primary"
                    :checked.sync="pubblicazione_facebook"
                />
              </CCol>
            </CRow>


          </CCardBody>
          <br>
        </CCard>
      </CCol>
    </CRow>
    <CButton v-if="this.$route.params.idManifesto != null"
             v-on:click="update"
             type="submit"
             :size="sizeLg"
             color="info">
      <CIcon name="cil-save"/>
      Aggiorna Manifesto
    </CButton>
    <div class="custom-button-manifesto-extra-space">
      <CButton v-if="this.$route.params.idManifesto == null"
               v-on:click="save(false)"
               type="submit"
               :size="sizeLg"
               color="info">
        <CIcon name="cil-save"/>
        Salva e ritorna alla lista
      </CButton>
      <CButton v-if="this.$route.params.idManifesto == null"
               v-on:click="save(true)"
               type="submit"
               :size="sizeLg"
               class="custom-button-space"
               color="info">
        <CIcon name="cil-save"/>
        Salva e inserisci un altro
      </CButton>


    </div>


    <!-- SALVATAGGIO -->
    <CModal
        title="Salvataggio del manifesto in corso"
        class="modal-save-update-manifesto"
        color="white"
        :size="sizeMd"
        fade
        centered
        :closeOnBackdrop="false"
        :show.sync="duplicatedManifesto"
    >

      <div class="custom-text-save-manifesto"> Risulta già un manifesto con questo nome</div>
      <br>
      <template #footer>
        <div></div>
      </template>

    </CModal>
    <!-- FINE SALVATAGGIO -->

    <!-- SALVATAGGIO -->
    <CModal
        title="Salvataggio del manifesto in corso"
        class="modal-save-update-manifesto"
        color="white"
        :size="sizeMd"
        fade
        centered
        :closeOnBackdrop="false"
        :show.sync="saveModal"
    >

      <div class="custom-text-save-manifesto"> Il salvataggio del manifesto è in corso presto sarai ridirezionato sulla
        lista
      </div>
      <br>

      <template #footer>
        <div></div>
      </template>

    </CModal>
    <!-- FINE SALVATAGGIO -->

    <!-- VALIDAZIONE DEI DATI -->
    <CModal
        title="Errore nel campo dei dati"
        class="modal-error-save-necrologi"
        color="white"
        :size="sizeXl"
        fade
        :closeOnBackdrop="false"
        centered
        :show.sync="validationModal"
    >
      <template #footer>
        <CButton size="lg" @click="validationModal = false" color="info">Chiudi</CButton>
        <CButton size="lg" @click="validationModal = false" color="info">Ok</CButton>
      </template>
      <br>
      <h4 style="text-align:center;">Devi inserire i dati con (*) prima di procedere con il salvataggio</h4>
      <br>
      <div class="custom-error-text">

        <div class="listaErrori" v-for="(n, index) in errorText">
          <h5>{{ index }} : {{ n }}</h5>
        </div>
      </div>
    </CModal>
    <!-- FINE VALIDAZIONE DEI DATI -->

    <br/>
  </div>
</template>

<script>

/**
 * API MANIFESTO
 */

const API_CREATE_MANIFESTO = '/dashboard/api/v0/manifesti/create'
const API_UPDATE_MANIFESTO = '/dashboard/api/v0/manifesti/update/'
const API_VIEW_MANIFESTO = '/dashboard/api/v0/manifesti/get/'

import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import {DatePicker} from 'v-calendar'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import MultiComuni from "@/views/funer24/components/MultiComuni";
import SingleComune from "@/views/funer24/components/SingleComune";
import ListaNecrologiPreview from "@/views/funer24/necrologi/ListaNecrologiPreview";
import UploadSinglePhotoAndPreview from "@/views/funer24/components/UploadSinglePhotoAndPreview";
import moment from 'moment'

export default {
  components: {
    MultiComuni,
    UploadSinglePhotoAndPreview,
    VSwatches,
    SingleComune,
    ListaNecrologiPreview,
    'v-date-picker': DatePicker,
    VueTimepicker,
  },
  data: function () {
    return {
      tipologia: 'Ringraziamento',
      foto_manifesto: null,
      nome_manifesto: '',
      scelta_rito: 'acceso',
      data_pubblicazione: null,
      ora_pubblicazione: null,
      stato_pubblicazione: null,
      necrologio: null,
      optionsNecrologi: [],
      testo_ringraziamenti: null,
      comuni: null,


      duplicatedManifesto: false,
      saveModal: false,
      validationModal: false,
      sizeLg: 'lg',
      sizeMd: 'md',
      sizeXl: 'xl',
      errorText: [],

      // Flag
      blocco_servizio_fiori: false,
      pubblicazione_facebook: true,
      pubblicazione_immediata: false,
      necessita_verifica: false,
      // fine-flag
    };
  },
  methods: {

    setMultiComuni(data) {
      this.comuni = data;
    },

    setComuneRito(data) {
      this.comune_luogo_rito_riccorenza = data;
    },

    selectNecrologio() {
      this.necrologio = null;
    },

    setNecrologio(data) {
      this.necrologio = null;
      this.necrologio = data;
      this.nome_manifesto = data.nome;
    },

    setSceltaRito(val) {
      this.scelta_rito = val;
    },

    computeDate(data) {

      if (data.data_pubblicazione !== null) {

        let data_pubblicazione = moment(data.data_pubblicazione).toDate()

        if (data_pubblicazione instanceof Object) {

          this.data_pubblicazione = data_pubblicazione;
          if (data_pubblicazione.getMinutes() < 10) {

            this.ora_pubblicazione = {
              'HH': data_pubblicazione.getHours().toString(),
              'mm': "0" + data_pubblicazione.getMinutes().toString()
            }

          } else {

            this.ora_pubblicazione = {
              'HH': data_pubblicazione.getHours().toString(),
              'mm': data_pubblicazione.getMinutes().toString()
            }
          }
        }
      }

    },

    validate(field) {
      if (field !== null) {
        return true;
      }
      return false;
    },

    validation() {

      this.errorText = [];

      if (this.necessita_verifica === false) {
        if (this.foto_manifesto === null || this.foto_manifesto === 'https://ucarecdn.com/null/') {
          this.errorText.push("La Foto del Manifesto non è stata inserita");
        }
      }

      if (!this.validate(this.nome_manifesto)) {
        this.errorText.push("Il campo Nome Completo non è stato inserito");
      }

      if (this.nome_manifesto !== null) {
        if (this.nome_manifesto.length > 0) {
          if (this.nome_manifesto === this.nome_manifesto.toUpperCase()) {
            this.errorText.push("Il campo nome completo deve essere scritto come segue: es: Mario Rossi e non MARIO ROSSI");
          }
        }
      }

      if (!this.validate(this.comuni)) {
        this.errorText.push("Il campo Comuni in cui pubblicare non è stato inserito");
      }

      if (this.pubblicazione_immediata) {

        if (!this.validate(this.data_pubblicazione)) {
          this.errorText.push("Il campo Data di pubblicazione non è stato inserito");
        }

        if (this.ora_pubblicazione !== null) {
          if (this.ora_pubblicazione.HH.length === 0) {
            this.errorText.push("Il campo ora di pubblicazione non è stato inserito");
          }

          if (this.ora_pubblicazione.mm.length === 0) {
            this.errorText.push("Il campo minuti di pubblicazione non è stato inserito");
          }

        } else {
          this.errorText.push("Il campo Ora Pubblicazione(*) non è stato inserito");
        }
      }

      if (this.errorText.length === 0) {
        return true;
      }
      return false;
    },

    update() {

      if (!this.validation()) {
        this.validationModal = true;
      }

      let comuniListId = []
      if (this.comuni !== null) {
        for (let comune of this.comuni) {
          comuniListId.push(comune.ref_id)
        }
      }

      let necrologioId = null;
      if (this.necrologio !== null) {
        necrologioId = this.necrologio.id;
      }


      if (this.validation()) {

        let data_pubblicazione = null;

        if (this.pubblicazione_immediata === true && this.data_pubblicazione !== null && this.ora_pubblicazione !== null) {
          this.data_pubblicazione.setHours(this.ora_pubblicazione.HH, this.ora_pubblicazione.mm)
          data_pubblicazione = moment(this.data_pubblicazione).utcOffset(0, true).format()
        }

        this.saveModal = true;

        axios.patch(API_UPDATE_MANIFESTO + this.$route.params.idManifesto,
            {

              nome_manifesto: this.nome_manifesto,
              foto_manifesto: this.foto_manifesto,
              address: this.nome_luogo_rito,
              nome_chiesa: this.nome_luogo_rito,
              tipologia: this.tipologia,
              necrologi: necrologioId,
              //stato_pubblicazione: this.stato_pubblicazione,
              blocco_servizio_fiori: this.blocco_servizio_fiori,
              pubblicazione_facebook: this.pubblicazione_facebook,
              scelta_rito_ricorrenza: this.scelta_rito,
              testo_ringraziamenti: this.testo_ringraziamenti,
              reset_foto: true

            }
        ).then((response) => {
          if (response.status === 500) {

            // TODO: manifesto esiste già con quel nome
          }
          // Manifesto aggiornato correttamente
          if (response.status === 200) {

            this.$router.push({
              name: 'listaManifesti',
            });

          }
          console.log(response);
        }, (error) => {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        });
      }

    },

    setHoursDate() {

      if (this.pubblicazione_immediata === true && this.data_pubblicazione !== null && this.ora_pubblicazione !== null) {
        this.data_pubblicazione.setHours(this.ora_pubblicazione.HH, this.ora_pubblicazione.mm)
        this.data_pubblicazione = moment(this.data_pubblicazione).utcOffset(0, true).format()
      }

    },

    save(again = false) {

      if (!this.validation()) {
        this.validationModal = true;
      }

      if (this.necessita_verifica === false) {
        this.stato_pubblicazione = 'PUBBLICATO';
      }

      // Se il manifesto necessità di controllo allora il suo stato di pubblicazione è verifica
      if (this.necessita_verifica === true) {
        this.foto_manifesto = 'https://ucarecdn.com/51580348-ca60-48d2-8af4-c2c43d28c070'
        this.stato_pubblicazione = 'VERIFICA';
      }

      let comuniListId = []
      if (this.comuni !== null) {
        for (let comune of this.comuni) {
          comuniListId.push(comune.ref_id)
        }
      }

      let necrologioId = null;
      if (this.necrologio !== null) {
        necrologioId = this.necrologio.id;
      }

      console.log(this.data_rito_ricorrenza)

      if (this.validation()) {

        let data_pubblicazione = null;

        if (this.pubblicazione_immediata === true && this.data_pubblicazione !== null && this.ora_pubblicazione !== null) {
          this.data_pubblicazione.setHours(this.ora_pubblicazione.HH, this.ora_pubblicazione.mm)
          data_pubblicazione = moment(this.data_pubblicazione).utcOffset(0, true).format()
        }

        axios.post(API_CREATE_MANIFESTO,
            {
              nome_manifesto: this.nome_manifesto,
              foto_manifesto: this.foto_manifesto,
              comuni: comuniListId,
              tipologia: this.tipologia,
              necrologi: necrologioId,
              stato_pubblicazione: this.stato_pubblicazione,
              blocco_servizio_fiori: this.blocco_servizio_fiori,
              data_pubblicazione: data_pubblicazione,
              pubblicazione_facebook: this.pubblicazione_facebook,
              pubblicazione_immediata: this.pubblicazione_immediata,
              scelta_rito_ricorrenza: this.scelta_rito,
              testo_ringraziamenti: this.testo_ringraziamenti,
            }
        ).then((response) => {
          if (response.status === 500) {
            // TODO: manifesto esiste già con quel nome far comparire un popup oppure controllare prima di arrivare qui
          }
          if (response.status === 201) {

            if (!again) {

              this.$router.push({
                name: 'listaManifesti',
              });

            } else {
              this.$router.go(0);
            }
          }
          console.log(response);
        }, (error) => {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        });
      }

    },

  },

  mounted() {

    if (this.$route.params.idManifesto !== undefined && this.$route.params.tipologia === this.tipologia) {
      axios
          .get(API_VIEW_MANIFESTO + this.$route.params.idManifesto)
          .then((response) => {
            if (response.status === 200) {

              this.computeDate(response.data)

              this.nome_manifesto = response.data.nome_manifesto;
              this.foto_manifesto = response.data.foto_manifesto;
              this.comuni = response.data.comuni;
              this.necrologi = response.data.necrologi;
              this.tipologia = response.data.tipologia;
              this.scelta_rito = response.data.scelta_rito_ricorrenza;
              this.blocco_servizio_fiori = response.data.blocco_servizio_fiori;
              this.blocco_funerale = response.data.blocco_funerale;
              this.pubblicazione_facebook = response.data.pubblicazione_facebook;
              this.pubblicazione_facebook_automatica = response.data.pubblicazione_facebook_automatica;
              this.testo_ringraziamenti = response.data.testo_ringraziamenti;


            }
          }, (error) => {
            console.log(error);
          });
    }
  },
  props: {
    id: {
      type: Number,
    }
  },
  name: "Ringraziamento"
}
</script>

<style>

button.btn.custom-button-space.btn-info.btn-lg {
  /* padding-left: 16px; */
  margin-left: 20px;
}

p.bold-text {
  font-weight: 700;
}

button.btn.btn-custom-button-clicked {
  background-color: rgba(237, 183, 12, 0.96) !important;
  color: white;
  font-weight: 600;
}

.bold-text.form-group {
  font-weight: 600;
}

h6.text.scelta-rito-ricorrenza {
  font-size: 18px;
  margin-bottom: 14px;
}

</style>
